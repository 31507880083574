<template>
  <div style="width: 100vw; max-width: 1200px; margin: auto;" class="p-4">
    <div ref="headerBox">
      <div class="flex justify-start items-center gap-2 px-1 mb-2">
        <img src="/cdn/img/logo/logo-144.png" class="w-6 -mt-0.5">
        <h1>Chat &mdash; Reservatie {{ apiDataGet.hash.split('-')[0] }}</h1>
      </div>
      <div class="flex flex-row justify-between px-2 font-bold">
        <span>{{ store.getters.isAuthenticated ? 'Klant' : 'Luchthavenvervoer Kevin' }}</span>
        <span>{{ store.getters.isAuthenticated ? 'Chauffeurs' : 'Jij' }}</span>
      </div>
    </div>
    <div ref="messagesBox" class="messages-box border border-gray-200 bg-gray-100 rounded-sm p-4 flex flex-col gap-2">
      <div
        v-for="m in messagesFiltered"
        :key="m.id"
        class="flex gap-4"
        :class="{ 'justify-end text-right': m.jij }"
      >
        <span class="message-bubble" :class="{ jij: m.jij }">
          <p>{{ m.message }}</p>
          <small v-if="store.getters.isAuthenticated && m.user_id" class="select-none">Chauffeur: {{ store.getters.chauffeur(m.user_id)?.label }}</small>
          <small class="select-none">
            {{ dateTimeStringLocale(m.creation_date) }}
            <span v-if="m.jij">
              <i class="fas fa-check ml-0.5 -mr-1.5"></i>
              <i v-if="m.is_read" class="fas fa-check"></i>
            </span>
          </small>
        </span>
      </div>
      <span v-if="loading" class="block text-center">Loading ...</span>
    </div>
    <div ref="inputBox" class="flex flex-row gap-2 mt-2 items-end">
      <input
        v-model="message"
        type="text"
        class="flex-1 w-full"
        placeholder="Jouw bericht..."
        @input="message = $event?.target?.value || ''"
      >
      <button class="btn success" :disabled="!message" @click="onSubmit">
        Verzenden
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { useElementSize, useWindowSize } from '@vueuse/core'
import clone from 'just-clone'

import { dateTimeStringLocale } from '@/functions/formatDate'
import { isThisNaN } from '@/functions/isThisNaN'
import useApi from '@/hooks/useApi'
import useGetApi from '@/hooks/useGetApi'

const api = useApi()
const route = useRoute()
const store = useStore()

const messagesBox = ref(null)
const headerBox = ref(null)
const inputBox = ref(null)
const { height: windowHeight } = useWindowSize()
const { height: headerHeight } = useElementSize(headerBox)
const { height: inputHeight } = useElementSize(inputBox)
const messagesBoxHeight = computed(() => {
  const height = Math.max(windowHeight.value, 0) - Math.max(headerHeight.value, 0) - Math.max(inputHeight.value, 0) - 40
  return `${isThisNaN(height) ? 0 : height}px`
})

const page = ref(1)
const message = ref('')
const apiDataGet = computed(() => ({ hash: String(route.params.hash || ''), page: Math.max(1, page.value) }))
const apiDataPost = computed(() => ({ hash: apiDataGet.value.hash, message: message.value }))

// const messages = []
const scrollToBottomChat = () => {
  nextTick(() => {
    if (messagesBox.value) {
      messagesBox.value.scrollTop = messagesBox.value.scrollHeight + 20
    }
  })
}

const onData = () => {
  scrollToBottomChat()
}
const { loading, data: messages, getData } = useGetApi('RIT_CHAT_MESSAGES', apiDataGet, { onData, watch: true })
const messagesFiltered = computed(() => {
  return clone(messages.value || []).map(el => {
    el.jij = (store.getters.isAuthenticated && el?.user_id > 0)
    el.jij = el.jij || (!store.getters.isAuthenticated && !el?.user_id)
    return el
  })
})
watch(messagesFiltered, scrollToBottomChat)

const onSubmit = () => {
  api('RIT_CHAT_SEND', apiDataPost).then(({ data }) => {
    message.value = ''
    if (data.record) {
      messages.value.push(data.record)
    }
  })
}

let interval = null
onMounted(() => {
  interval = setInterval(getData, 60000)
  scrollToBottomChat()
})
onBeforeUnmount(() => {
  clearInterval(interval)
  interval = null
})
</script>

<style lang="scss" scoped>
.messages-box {
  height: v-bind(messagesBoxHeight);
  max-height: v-bind(messagesBoxHeight);
  overflow-y: scroll;
}

.message-bubble {
  display: flex;
  flex-direction: column;
  padding: 4px 12px;
  border-radius: 8px;
  min-width: 160px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 0;
  max-width: 70%;
  @apply bg-pink-300 text-dashboard-dark;

  &.jij {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 0;
    @apply bg-teal-600;
    color: white;
  }

  small {
    font-size: 70%;
  }
}
</style>
